.wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
}
.title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 100%;

  color: #ffffff;
  padding-bottom: 20px;
}
.imageContainer {
  border: 1.5px solid #313234;
  border-radius: 7.21667px;
  padding: 20px 12px;
}

.image {
  width: 100%;
  border-radius: 12px;
}
.name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;

  color: #ffffff;
  padding-top: 12px;
}
@media only screen and (max-width: 1500px) {
  .wrapper {
    gap: 10px;
  }
  .imageContainer {
    padding: 12px 8px;
  }
}
@media only screen and (max-width: 1399px) {
  .name {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1199px) {
  .wrapper {
    gap: 15px;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 991px) {
  .wrapper {
    gap: 15px;
    grid-template-columns: repeat(4, 1fr);
  }
}
@media only screen and (max-width: 767px) {
  .wrapper {
    gap: 15px;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 575px) {
  .wrapper {
    gap: 15px;
    grid-template-columns: repeat(2, 1fr);
  }
}
