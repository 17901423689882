.getYourNft {
  background: #1d1f20;
  border: 1px solid #393b3d;
  border-radius: 6px;
  padding: 25px;
  height: 100%;
  width: 100%;
}

.headingAndLive {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 130%;

  color: #ffffff;
}
.timer {
  background: #1d1f20;
  border: 1px solid #393b3d;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 12px 12px;
}
.circle {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #f0b90a;
}
.live {
  text-transform: uppercase;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 125%;
  color: #ffffff;
  padding: 0 12px;
}
.time {
  color: #a2a1a4;
}
.mintingWrapper {
  padding: 30px 0;
}
.mintingDate {
  padding: 2px 0;
}
.key {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;

  color: #a2a1a4;
}
.value {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;

  color: #e8e7ea;
}
.title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 125%;

  color: #e8e7ea;
  position: relative;
  padding-bottom: 18px;
}
.maxMint {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 130%;

  color: #a2a1a4;
  top: 7px;
  right: 0;
  position: absolute;
}
.mintContainer {
  width: 100%;
  background: #1d1f20;
  border: 1px solid #393b3d;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 15px;
  margin-bottom: 18px;
}
.mint {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 130%;
  text-align: center;

  color: #f0b90a;
  cursor: pointer;
  margin: 0;
  inherits: none;
  border: none;
  outline: none;
  background-color: #1d1f20;
}
.mintDeactive {
  color: #7e7d7d;
  line-height: 130%;
}
.currencyContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}
.currency {
  background: #252728;
  border: 1px solid #454749;
  border-radius: 19.5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 125%;
  text-transform: uppercase;
  color: #a2a1a4;
  padding: 10px 25px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.currencyActive {
  color: #a2a1a4;
  background: #454749;
}
.ok {
  font-size: 17px;
  color: #e8e7ea;
  margin-right: 5px;
  margin-bottom: 2px;
}
.loader {
  background: #f0b90a;
  border-radius: 6px;
  padding: 10px 0;
  margin: 25px 0;
  border: none;
  outline: none;
  width: 100%;
}
.minting {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 125%;
  text-transform: uppercase;
  color: #000;
}
.transaction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 6px;
}
.transactionBottom {
  padding-bottom: 25px;
}
.transactionKey {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  color: #a2a1a4;
}
.transactionValue {
  color: #ffffff;
}
.progressAndMinted {
  padding-top: 25px;
}
.mintedContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
}

.minted {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 130%;
  text-align: right;

  color: #ffffff;
}

@media only screen and (max-width: 1399px) {
  .heading {
    font-size: 22px;
  }
  .live {
    font-size: 15px;
  }
  .key {
    font-size: 14px;
  }
  .value {
    font-size: 14px;
  }
  .title {
    font-size: 16px;
  }
  .maxMint {
    font-size: 15px;
  }
  .mint {
    font-size: 24px;
  }
  .currency {
    font-size: 15px;
    width: 130px;
  }
  .ok {
    font-size: 15px;
  }
  .transactionKey {
    font-size: 15px;
  }
  .minted {
    font-size: 15px;
  }
}
@media only screen and (max-width: 450px) {
  .getYourNft {
    padding: 12px;
  }

  .headingAndLive {
    flex-direction: column;
  }

  .heading {
    font-size: 20px;
    padding-bottom: 18px;
  }
  .live {
    font-size: 14px;
  }
  .key {
    font-size: 12px;
  }
  .value {
    font-size: 12px;
  }
  .title {
    font-size: 14px;
  }
  .maxMint {
    font-size: 13px;
  }
  .mint {
    font-size: 20px;
  }
  .currency {
    font-size: 13px;
    width: 130px;
  }
  .ok {
    font-size: 15px;
  }
  .transactionKey {
    font-size: 13px;
  }
  .minted {
    font-size: 13px;
  }
}
@media only screen and (max-width: 360px) {
  .currencyContainer {
    gap: 10px;
  }
  .currency {
    font-size: 14px;
    width: auto;
    min-width: 90px;
    padding: 8px 8px;
  }
  .ok {
    font-size: 15px;
  }
}
@media only screen and (max-width: 340px) {
  .key {
    font-size: 11px;
  }
  .value {
    font-size: 11px;
  }
}
