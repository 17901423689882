.home {
  padding: 0 35px;
  padding-top: 30px;
}
.wrapper {
  display: flex;
  align-items: flex-end;
}
.left {
  width: 100%;
  height: 100%;
  margin-right: 30px;
}
.right {
  min-width: 500px;
}
@media only screen and (max-width: 1399px) {
  .right {
    min-width: 400px;
  }
}

@media only screen and (max-width: 991px) {
  .wrapper {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }
  .left {
    margin: 0;
    margin-top: 50px;
  }
  .right {
    min-width: 500px;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 767px) {
  .home {
    padding: 0 20px;
    padding-top: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .home {
    padding: 0 10px;
    padding-top: 30px;
  }
  .right {
    min-width: 100%;
    margin: 0 auto;
  }
}
