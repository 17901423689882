.heroSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.imageContainer {
  background: #000000;
  border: 5.3593px solid #2b2c31;
  border-radius: 7.65614px;
  width: 63px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 15px;
}
.heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 55.6754px;
  line-height: 130%;

  color: #ffffff;
  padding: 20px 0;
}
.whiteList {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15.3123px;
  line-height: 124%;
  color: #ffffff;
  border: 1.53123px solid #efb970;
  border-radius: 3.82807px;
  display: flex;
  align-items: center;
  padding: 6px 10px;
}
.circle {
  display: block;
  width: 6.89px;
  height: 6.89px;
  border-radius: 100%;
  background: #efb970;
  margin-right: 8px;
}
.text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: #ffffff;
  padding: 50px 0;
  width: 70%;
}
@media only screen and (max-width: 1399px) {
  .heading {
    font-size: 48px;
  }
  .whiteList {
    font-size: 14px;
  }
  .circle {
    margin-right: 6px;
  }
  .text {
    font-size: 16px;
    padding: 40px 0;
    width: 100%;
  }
}
@media only screen and (max-width: 991px) {
  .text {
    width: 90%;
  }
}
@media only screen and (max-width: 575px) {
  .heading {
    font-size: 40px;
  }
  .whiteList {
    font-size: 13px;
  }
  .circle {
    margin-right: 6px;
  }
  .text {
    font-size: 14px;
    padding: 40px 0;
    width: 100%;
  }
}
