.spinnerContainer {
  display: flex;
  justify-content: center;
}

.loadingSpinner {
  width: 25px;
  height: 25px;
  border: 2px solid #14aa75; /* Light grey */
  border-top: 2px solid #035034; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 991px) {
  .loadingSpinner {
    width: 25px;
    height: 25px;
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #2a3550;
  }
}
@media only screen and (max-width: 520px) {
  .loadingSpinner {
    width: 25px;
    height: 25px;
    border: 3px solid #f3f3f3; /* Light grey */
    border-top: 3px solid #2a3550;
  }
}
