.header {
  background-color: #1c1d1f;
  border-bottom: 1px solid #595959;
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 0 auto;
  padding: 15px 35px;
}
.logoWrapper {
  text-decoration: none;
}
.logoContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.logo {
  width: 80px;
}
.logoText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20.7303px;
  line-height: 100%;
  color: #ffffff;
  padding-left: 20px;
}
.navBar {
  display: flex;
  align-items: center;
}
.navLink {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  color: #9fa0a2;
  text-decoration: none;
  padding-left: 45px;
  cursor: pointer;
}
.calender {
  display: flex;
  align-items: center;
  background: #252728;
  border: 1px solid #454649;
  border-radius: 5px;
  padding: 12px 18px;
  margin-left: 45px;
}
.calenderImage {
  width: 30px;
  margin-right: 20px;
}
.calenderText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  color: #e8e7ea;
}
.icon {
  display: none;
  color: #fff;
  font-size: 28px;
  cursor: pointer;
}

@media only screen and (max-width: 1399px) {
  .logo {
    width: 65px;
  }
  .logoText {
    font-size: 16px;
  }
  .navLink {
    font-size: 16px;
    padding-left: 30px;
  }
  .calender {
    margin-left: 30px;
    padding: 8px 15px;
  }
  .calenderImage {
    width: 24px;
    margin-right: 15px;
  }
  .calenderText {
    font-size: 18px;
  }
}
@media only screen and (min-width: 991px) {
  .sidebar {
    display: none;
  }
}
@media only screen and (max-width: 991px) {
  .icon {
    display: block;
  }
  .navBar {
    display: none;
  }
  .sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    background-color: #1c1d1f;
    position: absolute;
    width: 100%;
    z-index: 2;
    top: 95px;
    left: 0;
    right: 0;
    padding-top: 20px;
  }
  .navLink {
    padding: 0;
    margin: 15px 0;
  }
  .calender {
    margin: 0;
    margin: 15px 0;
  }
  .calenderImage {
    width: 20px;
    margin-right: 13px;
  }
  .calenderText {
    font-size: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .header {
    padding: 15px 20px;
  }
  .logo {
    width: 50px;
  }
  .logoText {
    font-size: 16px;
    padding-left: 10px;
  }
  .sidebar {
    top: 80px;
  }
}
@media only screen and (max-width: 575px) {
  .header {
    padding: 15px 10px;
  }
  .logo {
    width: 50px;
  }
  .icon {
    margin-right: 3px;
  }
  .logoText {
    font-size: 16px;
    padding-left: 10px;
  }
  .sidebar {
    top: 80px;
  }
}
